import React from 'react'
import styled, { css } from 'styled-components'
import ImageGallery from 'react-image-gallery'

interface ImageGalleryWrapperStProps {
  height: string
  maintainImageSize: boolean
  background: string
}

const ImageGalleryWrapperSt = styled.div<ImageGalleryWrapperStProps>`
  background: ${({ background }) => background};

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: ${({ height }) => height};
  }

  ${({ maintainImageSize }) => maintainImageSize && css`
    .image-gallery-slide .image-gallery-image {
      object-fit: cover;
    }
  `}

  .image-gallery-bullets .image-gallery-bullet {
    padding: 3.5px;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    background-color: white;
    box-shadow: none;
    border: none;

    &.active {
      opacity: 1;
      padding: 4px;
    }
  }
`

const TTOCarousel = ({
  imageUrls,
  startIndex = 0,
  height = `230px`,
  maintainImageSize = true,
  background = `url('/images/loading-rainbow.svg')`
}: {
  imageUrls: string[]
  startIndex?: number
  height?: string
  maintainImageSize?: boolean
  background?: string
}) => {
  const images = imageUrls?.map(url => ({
    original: url || '/images/loading-rainbow.svg',
  })) || [{original: '/images/loading-rainbow.svg'}]

  return (
    <ImageGalleryWrapperSt height={height} maintainImageSize={maintainImageSize} background={background} >
      <ImageGallery
        items={images}
        infinite
        showFullscreenButton={false}
        showThumbnails={false}
        showNav={false}
        showPlayButton={false}
        showBullets
        startIndex={startIndex}
      />
    </ImageGalleryWrapperSt>
  )
}

export default TTOCarousel
