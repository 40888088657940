
import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes, css } from 'styled-components'
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { PerformantImage, preloadImage } from '../../atoms/PerformantImage/PerformantImage'
import { UITypography } from '../../atoms/UIComponents/UITypography/UITypography'
import { RoundedButton } from '../../atoms/RoundedButton/RoundedButton'

interface ContainerProps {
  isOpen: boolean
}

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const ContainerSt = styled.div<ContainerProps>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
          height: 100vh;
          animation: ${slideUp} 0.3s ease-out;
        `
      : css`
          display: none;
          height: 0;
        `}

  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 5vh 3rem;
  background-color: ${({ theme }) => theme.colors.white};
`

const HeaderSt = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonSt = styled.button`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-family: Poppins, 'Montserrat';
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text02};

  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const ImageCarouselSt = styled.div`
  display: flex;
  align-items: center;
`

const DisplayedImageWithCreditSt = styled.div`
  position: relative;
  width: calc(100% - 200px);
  margin: 5vh auto;
  height: 80vh;

  img {
    object-fit: contain;
    vertical-align: bottom;
    height: 100%;
    width: 100%;
  }

  p {
    position: absolute;
    text-align: center;
    bottom: -2rem;
    width: 100%;
    color: ${({ theme }) => theme.colors.text02};
    opacity: 0.9;
  }
`

interface Props {
  images: {
    url: string
    id: string | number
    caption?: string
  }[]
  imageIdToDisplayOnOpen?: number | string
  isOpen: boolean
  onClose: () => void
}

const DesktopPhotoGallery = ({
  images,
  imageIdToDisplayOnOpen,
  isOpen,
  onClose,
}: Props) => {
  const isServingCloudinaryUrls = images?.[0]?.url?.includes('cloudinary')

  const [displayImageIndex, setDisplayImageIndex] = useState(0)

  const scrollableTarget = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!scrollableTarget?.current) return clearAllBodyScrollLocks

    if (isOpen) {
      disableBodyScroll(scrollableTarget.current)
      images?.forEach(img => preloadImage(3000, img.url));
    } else {
      enableBodyScroll(scrollableTarget.current)
    }

    return clearAllBodyScrollLocks
  }, [isOpen])

  useEffect(() => {
    if (!imageIdToDisplayOnOpen) return
    
    setDisplayImageIndex(
      images?.findIndex(img => img.id === imageIdToDisplayOnOpen) || 0
    )
  }, [imageIdToDisplayOnOpen])

  return (
    <div ref={scrollableTarget}>
      <ContainerSt isOpen={isOpen}>
        <HeaderSt>
          <ButtonSt onClick={onClose}>Close</ButtonSt>
          <UITypography variant="body2">
            {displayImageIndex + 1} / {images?.length || 1}
          </UITypography>
        </HeaderSt>
        <ImageCarouselSt>
          <RoundedButton
            disabled={displayImageIndex === 0}
            onClick={() => setDisplayImageIndex(curr => curr - 1)}
            direction="left"
            variant="lg"
          />
          <DisplayedImageWithCreditSt>
            {images?.map((img, index) => (
              <span key={index}>
                {index === displayImageIndex && (
                  <>
                    {isServingCloudinaryUrls ? (
                      <PerformantImage
                        cloudinaryURL={img.url}
                        desktopWidth={3000}
                      />
                    ) : (
                      <img src={img?.url} />
                    )}
                    <UITypography variant="body3">
                      {img?.caption}
                    </UITypography>
                  </>
                )}
              </span>
            ))}
          </DisplayedImageWithCreditSt>
          <RoundedButton
            disabled={displayImageIndex + 1 >= images?.length}
            onClick={() => setDisplayImageIndex(curr => curr + 1)}
            direction="right"
            variant="lg"
          />
        </ImageCarouselSt>
      </ContainerSt>
    </div>
  )
}

export default DesktopPhotoGallery
