import styled, { css } from 'styled-components'

type PossibleSizes = 'sm' | 'lg'

interface RoundedButtonStProps {
  isDisabled?: boolean
  variant: PossibleSizes
}

const RoundedButtonSt = styled.button<RoundedButtonStProps>`
  position: relative;

  ${({ variant }) =>
    variant === 'sm'
      ? css`
          height: 35px;
          width: 35px;
        `
      : css`
          height: 50px;
          width: 50px;
        `}

  border-radius: 50%;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.white : theme.colors.brandRed};

  border: ${({ isDisabled, theme }) =>
    isDisabled && `1px solid ${theme.colors.brandRed}`};

  :hover {
    opacity: ${({ isDisabled }) => (isDisabled ? 1 : 0.8)};
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  }
`

interface IconStProps {
  variant: PossibleSizes
}

const IconSt = styled.img<IconStProps>`
  ${({ variant }) =>
    variant === 'sm'
      ? css``
      : css`
          height: 30px;
        `}

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface Props {
  variant?: PossibleSizes
  direction: 'left' | 'right'
  disabled?: boolean
  onClick: () => void
}

export const RoundedButton = ({
  onClick,
  variant = 'sm',
  direction,
  disabled,
}: Props) => {
  const arrowPath = `/images/${
    disabled ? 'red' : 'white'
  }-arrow-${direction}.svg`

  return (
    <RoundedButtonSt
      isDisabled={disabled}
      variant={variant}
      onClick={() => !disabled && onClick()}
    >
      <IconSt src={arrowPath} variant={variant} />
    </RoundedButtonSt>
  )
}
