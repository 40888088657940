import React, { useRef, useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { PerformantImage } from '../../atoms/PerformantImage/PerformantImage'
import { UITypography } from '../../atoms/UIComponents/UITypography/UITypography'
import TTOCarousel from '../Carousel/Carousel'

interface ContainerProps {
  isOpen: boolean
}

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100vh);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`
const ScrollContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 60px);
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  padding: 0 0 4rem;
`

const ContainerSt = styled.div<ContainerProps>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
          height: 100vh;
          animation: ${slideUp} 0.3s ease-out;
        `
      : css`
          display: none;
          height: 0;
        `}
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 60px 0 3rem;

  background-color: ${({ theme }) => theme.colors.white};
`

const HeaderSt = styled.div`
  height: 60px;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
`

const CloseButtonSt = styled.button``

const ImagesGridSt = styled.div``

const MainImageWithCreditSt = styled.div`
  position: relative;
  height: 220px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: absolute;
    bottom: 0;
    left: 0.625rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    width: calc(100% - 0.625rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const MainImageSt = styled(PerformantImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  display: block;
`


const MainImageNotCloudinarySt = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  vertical-align: bottom;
  display: block;
`

const SecondaryImagesSt = styled.div`
  display: flex;
  justify-content: space-between;

  > img:nth-of-type(2) {
    margin-left: 10px;
  }
`

const SecondaryImageWithCreditSt = styled.div`
  position: relative;
  height: 160px;
  width: calc(50% - 5px);
  margin-bottom: 10px;

  p {
    position: absolute;
    bottom: 0;
    left: 0.625rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    width: calc(100% - 0.625rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const SecondaryImageSt = styled(PerformantImage)`
  height: 100%;
  width: 100%;
  display: block;
  flex-grow: 1;
  object-fit: cover;
  vertical-align: bottom;
`

const SecondaryImageNotCloudinarySt = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  flex-grow: 1;
  object-fit: cover;
  vertical-align: bottom;
`

const IconSt = styled.img`
  height: 20px;
`

const CarouselContainerSt = styled.div`
  margin: auto 0;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1c1e21;
`

const CarouselCloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 20px;
`

const CarouselWrapperSt = styled.div`
  margin: 10vh 0;
`

interface ImageContract {
  id: string | number
  url: string
  caption?: string
}

interface Props {
  images: ImageContract[]
  isOpen: boolean
  onClose: () => void
}

const MobilePhotoGallery = ({ images, isOpen, onClose }: Props) => {
  const isServingCloudinaryUrls = images?.[0]?.url?.includes('cloudinary')

  const scrollableTarget = useRef<HTMLDivElement>(null)
  const [showCarousel, setShowCarousel] = useState(false)
  const [carouselStartIndex, setCarouselStartIndex] = useState(0)

  useEffect(() => {
    if (!scrollableTarget?.current) return clearAllBodyScrollLocks

    if (isOpen) {
      disableBodyScroll(scrollableTarget.current)
    } else {
      enableBodyScroll(scrollableTarget.current)
    }

    return clearAllBodyScrollLocks
  }, [isOpen])

  const handleShowCarousel = (imgArrayIndex: number, imgIndex: number) => {
    setCarouselStartIndex((imgArrayIndex * 3) + imgIndex)
    setShowCarousel(true)
  }

  const imagesGroupedInThrees = images?.reduce(
    (accumulator: ImageContract[][], currentValue, currentIndex) => {
      const accumulatorCopy = [...accumulator]
      if (currentIndex !== 0 && currentIndex % 3 === 0) {
        accumulatorCopy?.push([currentValue])
      } else {
        accumulatorCopy[accumulatorCopy.length - 1]?.push(currentValue)
      }
      return accumulatorCopy
    },
    [[]] as ImageContract[][]
  )

  return (
    <ContainerSt isOpen={isOpen}>
      {showCarousel && (
        <CarouselContainerSt>
          <CarouselCloseButton onClick={() => { setShowCarousel(false) }}>
            <IconSt src="/images/white-close.svg" />
          </CarouselCloseButton>
          <CarouselWrapperSt>
            <TTOCarousel imageUrls={images.map(i => i.url)} height="80vh" background="#1C1E23" maintainImageSize={false} startIndex={carouselStartIndex} />
          </CarouselWrapperSt>
        </CarouselContainerSt>
      )}
      <HeaderSt>
        <CloseButtonSt onClick={onClose}>
          <IconSt src="/images/left-arrow.svg" />
        </CloseButtonSt>
      </HeaderSt>
      <ScrollContainer ref={scrollableTarget}>
        <ImagesGridSt>
          {imagesGroupedInThrees?.map((imgArray, imgArrayIndex) => {
            if (imgArray.length !== 3) {
              return (
                <span key={imgArrayIndex}>
                  <MainImageWithCreditSt>
                    {isServingCloudinaryUrls ? (
                      <MainImageSt
                        cloudinaryURL={imgArray[0]?.url}
                        onClick={() => {
                          handleShowCarousel(imgArrayIndex, 0)
                        }}
                      />
                    ) : (
                      <MainImageNotCloudinarySt
                        src={imgArray[0]?.url}
                        onClick={() => {
                          handleShowCarousel(imgArrayIndex, 0)
                        }}
                      />
                    )}
                    <UITypography variant="body3">
                      {imgArray[0]?.caption}
                    </UITypography>
                  </MainImageWithCreditSt>
                  {imgArray[1]?.url && (
                    <MainImageWithCreditSt>
                      {isServingCloudinaryUrls ? (
                        <MainImageSt
                          cloudinaryURL={imgArray[1]?.url}
                          onClick={() => {
                            handleShowCarousel(imgArrayIndex, 1)
                          }}
                        />
                      ) : (
                        <MainImageNotCloudinarySt
                          src={imgArray[1]?.url}
                          onClick={() => {
                            handleShowCarousel(imgArrayIndex, 1)
                          }}
                        />
                      )}
                      <UITypography variant="body3">
                        {imgArray[1]?.caption}
                      </UITypography>
                    </MainImageWithCreditSt>
                  )}
                </span>
              )
            } else {
              return (
                <span key={imgArrayIndex}>
                  <MainImageWithCreditSt>
                    {isServingCloudinaryUrls ? (
                      <MainImageSt
                        cloudinaryURL={imgArray[0]?.url}
                        onClick={() => {
                          handleShowCarousel(imgArrayIndex, 0)
                        }}
                      />
                    ) : (
                      <MainImageNotCloudinarySt
                        src={imgArray[0]?.url}
                        onClick={() => {
                          handleShowCarousel(imgArrayIndex, 0)
                        }}
                      />
                    )}
                    <UITypography variant="body3">
                      {imgArray[0]?.caption}
                    </UITypography>
                  </MainImageWithCreditSt>
                  <SecondaryImagesSt>
                    {imgArray[1]?.url && (
                      <SecondaryImageWithCreditSt>
                        {isServingCloudinaryUrls ? (
                          <SecondaryImageSt
                            cloudinaryURL={imgArray[1]?.url}
                            onClick={() => {
                              handleShowCarousel(imgArrayIndex, 1)
                            }}
                          />
                        ) : (
                          <SecondaryImageNotCloudinarySt
                            src={imgArray[1]?.url}
                            onClick={() => {
                              handleShowCarousel(imgArrayIndex, 1)
                            }}
                          />
                        )}
                        <UITypography variant="body3">
                          {imgArray[1]?.caption}
                        </UITypography>
                      </SecondaryImageWithCreditSt>
                    )}
                    {imgArray[2]?.url && (
                      <SecondaryImageWithCreditSt>
                        {isServingCloudinaryUrls ? (
                          <SecondaryImageSt
                            cloudinaryURL={imgArray[2]?.url}
                            onClick={() => {
                              handleShowCarousel(imgArrayIndex, 2)
                            }}
                          />
                        ) : (
                          <SecondaryImageNotCloudinarySt
                            src={imgArray[2]?.url}
                            onClick={() => {
                              handleShowCarousel(imgArrayIndex, 2)
                            }}
                          />
                        )}
                        <UITypography variant="body3">
                          {imgArray[2]?.caption}
                        </UITypography>
                      </SecondaryImageWithCreditSt>
                    )}
                  </SecondaryImagesSt>
                </span>
              )
            }
          })}
        </ImagesGridSt>
      </ScrollContainer>
    </ContainerSt>
  )
}

export default MobilePhotoGallery
